<template>
  <div v-bind="$attrs">
    <b-checkbox
      v-if="enterpriseAllowLikes"
      id="allow-likes"
      :checked="articleAllowLikes"
      class="mb-1"
      @change="updateAllowLikes"
    >
      <div class="cursor-pointer">Allow Likes</div>
    </b-checkbox>
    <b-checkbox
      v-if="enterpriseAllowComments"
      id="allow-comments"
      :checked="articleAllowComments"
      class="mb-1"
      @change="updateAllowComments"
    >
      <div class="cursor-pointer">Allow Comments</div>
    </b-checkbox>
    <label for="is-published">Status</label>
    <b-form-select
      id="is-published"
      :value="articleIsPublished"
      class="cursor-pointer"
      @change="updateIsPublished"
    >
      <b-form-select-option :value="false">Draft</b-form-select-option>
      <b-form-select-option :value="true">Published</b-form-select-option>
    </b-form-select>
  </div>
</template>

<script>
export default {
  name: "ArticleSettingsEditor",
  props: {
    articleAllowLikes: Boolean,
    articleAllowComments: Boolean,
    articleIsPublished: Boolean,
  },
  computed: {
    enterpriseAllowLikes() {
      return this.$store.state.user.currentUser.enterprise_allow_the_hub_likes;
    },
    enterpriseAllowComments() {
      return this.$store.state.user.currentUser
        .enterprise_allow_the_hub_comments;
    },
  },
  methods: {
    updateAllowLikes(v) {
      this.$emit("update:allowLikes", v);
    },
    updateAllowComments(v) {
      this.$emit("update:allowComments", v);
    },
    updateIsPublished(v) {
      this.$emit("update:isPublished", v);
    },
  },
};
</script>

<style lang="scss" scoped></style>
