<template>
  <b-button :key="tag.id"
            :variant="variant"
            class="d-flex align-items-center mt-1 mr-1"
            style="padding-inline: .5rem"
            @mouseenter="hovering = true"
            @mouseleave="hovering = false"
            @click="$emit('click', tag.id)"
  >
    {{ tag.name }}
    <feather-icon :icon="icon"
                  size="16"
                  style="margin-left: .5rem"
    />
  </b-button>
</template>

<script>
export default {
  name: "Tag",
  props: {
    tag: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      hovering: false,
    }
  },
  computed: {
    variant() {
      return this.hovering ? 'danger' : 'success'
    },
    icon() {
      return this.hovering ? 'Trash2Icon' : 'TagIcon'
    }
  }
}
</script>

<style scoped lang="scss">

</style>
