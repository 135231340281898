<template>
  <div v-bind="$attrs">
    <b-dropdown text="Select Tags" variant="primary">
      <b-dropdown-form>
        <b-input
          id="tag-search-field"
          v-model="filters.search"
          placeholder="Search..."
          type="search"
          @input="getMoreTags(1)"
        />
      </b-dropdown-form>

      <b-dropdown-divider />

      <b-dropdown-item
        v-for="item in tags"
        :key="item.id"
        @click="addToSelectedTags(item)"
      >
        {{ item.name }}
      </b-dropdown-item>
      <b-row class="mb-0">
        <b-col v-if="total_count > filters.page_size" class="my-1" md="6">
          <b-pagination
            v-model="currentPage"
            :per-page="filters.page_size"
            :total-rows="total_count"
            class="mx-1"
            @change="getMoreTags"
          />
        </b-col>
        <p v-if="total_count === 0" class="col-6 ml-2 my-1 text-nowrap">
          No records
        </p>
      </b-row>
    </b-dropdown>

    <!-- Tags Selector -->
    <div v-if="articleTags && articleTags.length > 0" class="d-flex flex-wrap">
      <tag
        v-for="tag in articleTags"
        :key="tag.id"
        :tag="tag"
        @click="removeFromSelectedTags"
      />
    </div>

    <!-- Settings -->
  </div>
</template>

<script>
import TheHubService from "@/services/TheHubService";
import Tag from "@/components/the-hub/Tag.vue";

export default {
  name: "ArticleTagsEditor",
  components: { Tag },
  props: {
    articleTags: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      tags: [],
      filters: {
        page: 0,
        page_size: 10,
        search: "",
      },
      currentPage: 1,
      total_count: 0,
    };
  },
  mounted() {
    this.getTags();
  },
  methods: {
    getMoreTags(page) {
      this.filters.page = page - 1;
      this.currentPage = page;
      this.getTags();
    },
    async getTags() {
      try {
        const { data } = await TheHubService.listTags(this.filters);
        this.tags = data.data;

        this.filters.page = data.meta.page;
        this.filters.page_size = data.meta.page_size;
        this.total_count = data.meta.total_count;
      } catch (err) {
        this.$toast.error(
          "Error fetching tags. Please refresh and try again.",
          { toastClassName: ["toast-std", "warning-toast"] }
        );
      }
    },
    addToSelectedTags(tag) {
      if (
        this.$props.articleTags.some(articleTag => articleTag.id === tag.id)
      )
        return;
      this.$emit("update", [...this.$props.articleTags, tag]);
    },
    removeFromSelectedTags(id) {
      this.$emit(
        "update",
        this.$props.articleTags.filter(tag => tag.id !== id)
      );
    },
  },
};
</script>

<style lang="scss" scoped></style>
