<template>
  <div>
    <b-dropdown text="Select Author" variant="primary">
      <b-dropdown-form>
        <b-input
          id="author-search-field"
          v-model="filters.search"
          placeholder="Search..."
          type="search"
          @input="getMoreAuthors(1)"
        />
      </b-dropdown-form>

      <b-dropdown-divider />

      <b-dropdown-item
        v-for="item in authors"
        :key="item.id"
        @click="updateAuthor(item)"
      >
        {{ item.name }}
      </b-dropdown-item>
      <b-row class="mb-0">
        <b-col v-if="total_count > filters.page_size" class="my-1" md="6">
          <b-pagination
            v-model="currentPage"
            :per-page="filters.page_size"
            :total-rows="total_count"
            class="mx-1"
            @change="getMoreAuthors"
          />
        </b-col>
        <p v-if="total_count === 0" class="col-6 ml-2 my-1 text-nowrap">
          No records
        </p>
      </b-row>
    </b-dropdown>

    <div class="mt-1">
      <strong>Author: </strong>{{ $props.articleAuthor.name }}
    </div>
  </div>
</template>

<script>
import UserService from "@/services/UserService";

export default {
  name: "ArticleAuthorEditor",
  props: {
    articleAuthor: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      authors: [],
      filters: {
        page: 0,
        page_size: 10,
        search: "",
        role: "admin",
      },
      total_count: 0,
      currentPage: 1,
    };
  },
  mounted() {
    this.getAuthors();
  },
  methods: {
    getMoreAuthors(page) {
      this.filters.page = page - 1;
      this.currentPage = page;
      this.getAuthors();
    },
    async getAuthors() {
      try {
        const { data } = await UserService.getUsers(this.filters);
        this.authors = data.data;

        this.filters.page = data.meta.page;
        this.filters.page_size = data.meta.page_size;
        this.total_count = data.meta.total_count;
      } catch (error) {
        this.$toast.error(
          "Unable to load authors. Please refresh and try again.",
          { toastClassName: ["toast-std", "warning-toast"] }
        );
      }
    },
    updateAuthor(author) {
      if (this.$props.articleAuthor.uid === author.uid) return;
      this.$emit("update:author", author);
    },
  },
};
</script>

<style lang="scss" scoped></style>
