<template>
  <div v-if="articleData" class="mx-auto my-4" style="max-width: 75%">
    <!-- Title Section -->
    <section class="mb-4">
      <div class="d-flex align-items-center">
        <div v-if="thumbnail" class="article-image">
          <img :src="thumbnail" alt="Article Thumbnail" />
        </div>
        <div class="flex-grow-1">
          <!-- Date -->
          <div class="d-flex align-items-center mb-1">
            <feather-icon
              class="mr-1"
              icon="CalendarIcon"
              size="32"
              width="50"
            />
            <h4 style="margin-top: 0.5rem">
              {{ articleData.date | formatDate }}
            </h4>
          </div>

          <!-- Author -->
          <div class="d-flex align-items-center">
            <div v-if="authorProfilePictureURL" class="author-profile">
              <img :src="authorProfilePictureURL" alt="" />
            </div>
            <feather-icon v-else class="mr-1" icon="UserIcon" size="24" />
            <h4>{{ articleData.author.name }}</h4>
          </div>

          <!-- Title -->
          <h1 class="font-large-2">{{ articleData.title }}</h1>

          <!-- Excerpt -->
          <h4>{{ articleData.excerpt }}</h4>
        </div>
      </div>
    </section>

    <!-- Video -->
    <section v-if="video" class="mb-4 article-video">
      <video :src="video" class="full-width full-height" controls />
    </section>

    <!-- Body -->
    <section class="mb-4 d-flex">
      <div class="article-body ck-content" v-html="articleData.body" />
    </section>

    <!-- PDF -->
    <section v-if="pdf" class="mb-4 article-pdf">
      <PDFView
        v-if="pdf"
        :src.sync="pdf"
        :download-feature-visible="false"
        :sidebar-feature-visible="false"
        :dropzone-feature-visible="false"
      />
    </section>
  </div>
</template>

<script>
import CountryService from "@/services/CountryService";
import {PDFView} from '@gabrielbu/vue-pdf-viewer';

export default {
  name: "ArticlePreview",
  components: {
    PDFView,
  },
  props: {
    article: {
      type: Object,
      required: true,
    },
    thumbnail: {
      type: String,
      default: "",
    },
    video: {
      type: String,
      default: "",
    },
    pdf: {
      type: String,
      default: "",
    }
  },
  data() {
    return {
      articleData: null,
      authorProfilePictureURL: "",
    };
  },
  watch: {
    article: {
      immediate: true,
      handler(newVal) {
        this.articleData = newVal;
      },
    },
  },
  beforeMount() {
    if (this.$props.article.author.profile_picture) {
      this.authorProfilePictureURL = `${CountryService.getApiUrl()}/files/${
        this.$props.article.author.profile_picture.file_location
      }/${this.$props.article.author.profile_picture.filename}`;
    }
  },
};
</script>

<style lang="scss" scoped>
.article-image {
  width: 1000px;
  aspect-ratio: 1/1;
  position: relative;
  margin-right: 2rem;
  border-radius: 16px;
  overflow: hidden;

  img {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}

.article-video {
  display: flex;
  align-items: center;
  justify-content: center;

  video {
    width: 100%;
  }
}

.article-body {
  border: 1px solid lightgray;
  border-radius: 16px;
  padding: 2rem;
  flex-grow: 1;
}

.author-profile {
  width: 50px;
  aspect-ratio: 1/1;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 1rem;

  img {
    width: 100%;
    object-fit: cover;
  }
}
</style>
