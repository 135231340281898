<template>
  <ckeditor v-model="value" :config="editorConfig" :editor="editor" />
</template>

<script>
import { Font } from "@ckeditor/ckeditor5-font";
import { List } from "@ckeditor/ckeditor5-list";
import { AutoLink } from "@ckeditor/ckeditor5-link";
import { Heading } from "@ckeditor/ckeditor5-heading";
import { Alignment } from "@ckeditor/ckeditor5-alignment";
import { Paragraph } from "@ckeditor/ckeditor5-paragraph";
import { Autoformat } from "@ckeditor/ckeditor5-autoformat";
import { Essentials } from "@ckeditor/ckeditor5-essentials";
import { BlockQuote } from "@ckeditor/ckeditor5-block-quote";
import { Table, TableToolbar } from "@ckeditor/ckeditor5-table";
import { ClassicEditor } from "@ckeditor/ckeditor5-editor-classic";
import { TheHubArticleUploadAdapterPlugin } from "@/services/CkImageUpload";
import { PasteFromOffice } from "@ckeditor/ckeditor5-paste-from-office";
import {
  Bold,
  Italic,
  Strikethrough,
  Underline,
} from "@ckeditor/ckeditor5-basic-styles";
import {
  Image,
  ImageCaption,
  ImageResize,
  ImageStyle,
  ImageToolbar,
  ImageUpload,
} from "@ckeditor/ckeditor5-image";

export default {
  name: "CkEditor",
  props: {
    modelValue: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      value: "",
      editor: ClassicEditor,
      editorConfig: {
        plugins: [
          Heading,
          Essentials,
          Bold,
          Italic,
          Strikethrough,
          Underline,
          List,
          AutoLink,
          Paragraph,
          Alignment,
          Table,
          TableToolbar,
          Image,
          ImageToolbar,
          ImageCaption,
          ImageStyle,
          ImageUpload,
          Font,
          BlockQuote,
          ImageResize,
          Autoformat,
          PasteFromOffice,
          TheHubArticleUploadAdapterPlugin,
        ],
        toolbar: {
          items: [
            "heading",
            "|",
            "bold",
            "italic",
            "underline",
            "strikethrough",
            "fontFamily",
            "fontSize",
            "fontColor",
            "fontBackgroundColor",
            "|",
            "blockQuote",
            "bulletedList",
            "numberedList",
            "|",
            "alignment",
            "|",
            "imageUpload",
            "insertTable",
            "|",
            "undo",
            "redo",
          ],
        },
        table: {
          contentToolbar: [
            "tableColumn",
            "tableRow",
            "mergeTableCells",
            "tableProperties",
            "tableCellProperties",
          ],
        },
        image: {
          toolbar: [
            "imageStyle:alignLeft",
            "imageStyle:alignCenter",
            "imageStyle:alignRight",
            "|",
            "imageResize",
            "|",
            "imageTextAlternative",
            "toggleImageCaption",
          ],
          resizeOptions: [
            {
              name: "resizeImage:original",
              value: null,
              icon: "original",
            },
            {
              name: "resizeImage:25",
              value: "25",
              icon: "small",
            },
            {
              name: "resizeImage:50",
              value: "50",
              icon: "medium",
            },
            {
              name: "resizeImage:75",
              value: "75",
              icon: "large",
            },
            {
              name: "resizeImage:100",
              value: "100",
              icon: "large",
            },
          ],
        },
      },
    };
  },
  watch: {
    value() {
      this.$emit("update:modelValue", this.value);
    },
  },
  beforeMount() {
    this.value = this.$props.modelValue;
  },
};
</script>

<style>
.ck-editor {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    color: black;
  }

  .ck-content {
    min-height: 430px !important;
  }
}

.ck-powered-by {
  display: none !important;
}
</style>
